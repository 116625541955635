import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';

var firebaseConfig = {
    apiKey: "AIzaSyCMHxmvWeOeAlizEvwFtmtkILTStIjBcEI",
    authDomain: "reservapp-cacfd.firebaseapp.com",
    databaseURL: "https://reservapp-cacfd.firebaseio.com",
    projectId: "reservapp-cacfd",
    storageBucket: "reservapp-cacfd.appspot.com",
    messagingSenderId: "963971888946",
    appId: "1:963971888946:web:a050a9e03a4f21d791492b",
    measurementId: "G-SNS4GFRM1P"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const secondary = firebase.initializeApp(firebaseConfig, "secondary").auth()
export default firebase;