import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        display: "flex"
    },
    card: {
        maxWidth: 500,
        margin: "auto",
        boxShadow: '0 3.2px 7.2px 0 rgba(0,0,0,.18), 0 0.6px 1.8px 0 rgba(0,0,0,.11)',
        // [theme.breakpoints.down('xs')]: {
        //     boxShadow: '0 0px 0px 0 rgba(0,0,0,.18), 0 0px 0px 0 rgba(0,0,0,.11)'
        // }
        // marginBottom: 16,
        // padding: 16,
        // flexGrow: 1,
        // '&:hover': {
        //     boxShadow: '0 6.4px 14.4px 0 rgba(0,0,0,.18), 0 0.12px 2.16px 0 rgba(0,0,0,.11)',
        // }
    },
    item: {
        padding: 16,
        paddingTop: 0
    },
    input: {
        flexGrow: 1,
        marginTop: 16,
        marginBottom: 0
    },
    title: {
        marginTop: 16,
        fontWeight: "bold"
    }
}));