import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        // width: "100%",
        height: "100vh",
        display: "flex"
    },
    content: {
        // height: "80%",
        display: "flex"
    },
    card: {
        margin: "auto",
        marginTop: 0,
        flexGrow: 1,
        height: "100%",
        overflow: "auto",
        borderLeft: '4px solid #62bd6e',
        border: '1px solid #62bd6e',
        // scrollbarWidth: "none",
        // overflowY: "hidden",
        '&::webkit-scrollbar': {
            display: "none",
            scrollbarWidth: "none"
        },
        // boxShadow: '0 3.2px 7.2px 0 rgba(0,0,0,.18), 0 0.6px 1.8px 0 rgba(0,0,0,.11)',
        // marginBottom: 16,
        // padding: 16,
        // flexGrow: 1,
        // '&:hover': {
        //     boxShadow: '0 6.4px 14.4px 0 rgba(0,0,0,.18), 0 0.12px 2.16px 0 rgba(0,0,0,.11)',
        // }
    },
    item: {
        display: "block",
        height: "min-content",
        '&:hover': {
            boxShadow: '0 6.4px 14.4px 0 rgba(0,0,0,.18), 0 0.12px 2.16px 0 rgba(0,0,0,.11)',
        }
    },
    itemSelected: {
        display: "block",
        height: "min-content",
        background: '#62bd6e',
        color: "#FFFFFF",
        '&:hover': {
            boxShadow: '0 6.4px 14.4px 0 rgba(0,0,0,.18), 0 0.12px 2.16px 0 rgba(0,0,0,.11)',
        }
    },
    titleDiv: {
        marginTop: "auto",
        marginBottom: 4,
        marginLeft: 32,
        width: "100%",
        display: "flex",
        marginRight: 16
    },
    cardTitle: {
        color: '#62bd6e',
        margin: "auto",
        fontWeight: "bold"
    },
    title: {
        margin: 16,
        marginTop: 8,
        marginBottom: 0,
        fontWeight: "bold",
    },
    subtitle: {
        marginLeft: 16,
        fontSize: "small"
    },
    divider: {
        background: '#B5B5B5',
        width: '100%',
        marginTop: 8
    },
    fab: {
        position: 'absolute',
        bottom: 16,
        right: 16
    },
    fabAdmin: {
        position: 'absolute',
        bottom: 16,
        right: 16
    },
    searchDiv: {
        marginBottom: 4,
        width: '100%',
        display: 'flex'
    },
    search: {
        flexGrow: 1,
        marginLeft: 4,
        marginRight: 4
    },
    drawer: {
        width: 500,
        display: "flex",
        padding: 16,
        height: '100%',
        marginBottom: 16
    },
    closeIcon: {
        marginRight: 8,
        marginTop: 8,
        margin: "auto",
        color: "#B5B5B5",
        "&:hover": {
            color: "#62bd6e"
        }
    },
    closeIconSelected: {
        marginRight: 8,
        marginTop: 8,
        margin: "auto",
        color: "#FFFFFF",
        "&:hover": {
            color: "#F4F4F4"
        }
    },
    removeItemButton: {
        width: "100%",
    },
}));

// export const theme = useTheme()