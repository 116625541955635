import React, { useState } from "react";
import { useStyles } from "./style";

// Material UI
import {
    Grid,
    Card,
    Button,
    TextField,
    Typography,
    Snackbar,
    Popover
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface Props {
    email: string;
    setEmail: (value: string) => void;
    password: string;
    setPassword: (value: string) => void;
    handleSignin: (e: any) => void;
    sendEmail: (e: any) => void;
    openSnackbar: Array<boolean>;
}

const LoginView: React.FC<Props> = ({
    email,
    setEmail,
    password,
    setPassword,
    handleSignin,
    sendEmail,
    openSnackbar
}) => {
    const classes = useStyles();
    const [openPopover, setOpenPopover] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>();

    const handleForgotPassword = (e: any) => {
        setOpenPopover(true)
        setAnchorEl(e.target)
    }

    return (
        <Grid className={classes.root}>

            <Card className={classes.card} style={{ boxShadow: '0 3.2px 7.2px 0 rgba(0,0,0,.18), 0 0.6px 1.8px 0 rgba(0,0,0,.11)', borderRadius: 8 }}>
                <Grid container>

                    <Grid container justify="center" style={{ marginTop: 16, marginBottom: 16 }}>
                        <img alt="logo" src="Logo reservApp Verde.png" height="50px" />
                    </Grid>
                    {/* 
                    <Grid container justify="center" className={classes.item}>
                        <Typography variant="h5" style={{ fontWeight: "bold", marginTop: 16 }}>Iniciar sesión</Typography>
                    </Grid> */}

                    <Grid container justify="center" className={classes.item}>
                        <TextField
                            variant="outlined"
                            label="Correo"
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            onKeyDown={(e) => { e.key === 'Enter' && handleSignin(e) }}
                            size="small"
                            className={classes.input}
                        />
                    </Grid>

                    <Grid container justify="center" className={classes.item}>
                        <TextField
                            variant="outlined"
                            label="Contraseña"
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            onKeyDown={(e) => { e.key === 'Enter' && handleSignin(e) }}
                            size="small"
                            className={classes.input}
                        />
                    </Grid>

                    <Grid container justify="flex-end">
                        <Typography variant="caption" style={{ marginRight: 16, marginTop: -16, marginBottom: 16 }} onClick={handleForgotPassword}>¿Olvidaste tu contraseña?</Typography>
                    </Grid>

                    <Grid container justify="center">
                        <Popover
                            open={openPopover}
                            anchorEl={anchorEl}
                            onClose={() => setOpenPopover(false)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Grid container className={classes.item}>
                                <Grid container justify="center" style={{ margin: 16 }}>
                                    ¿Desea enviar un correo eléctronico?
                                </Grid>
                                <Grid container justify="flex-end">
                                    <Button onClick={sendEmail} variant="outlined" size="small" color="primary">Enviar</Button>
                                </Grid>
                            </Grid>
                        </Popover>
                        <Button variant="contained" className={classes.input} color="primary" onClick={handleSignin}>Iniciar sesión</Button>
                    </Grid>

                </Grid>
            </Card>

            <Snackbar open={openSnackbar[0]}>
                <Alert severity="success">
                    Correo enviado.
                </Alert>
            </Snackbar>

            <Snackbar open={openSnackbar[1]}>
                <Alert severity="error">
                    Contraseña incorrecta.
                </Alert>
            </Snackbar>

            <Snackbar open={openSnackbar[2]}>
                <Alert severity="error">
                    No existe ese correo.
                </Alert>
            </Snackbar>

            <Snackbar open={openSnackbar[3]}>
                <Alert severity="error">
                    Unauthorized
                </Alert>
            </Snackbar>

            <Snackbar open={openSnackbar[4]}>
                <Alert severity="error">
                    Oops! Algo salió mal.
                </Alert>
            </Snackbar>

        </Grid>
    );
}

export default LoginView;