import React, { useState } from "react";
import { useStyles } from "./style";
import { Restaurant, Branch, User } from "../../values/interfaces";

// Material UI
import {
    Dialog,
    Grid,
    TextField,
    Typography,
    Divider,
    Zoom,
    Fab,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Snackbar
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface Props {
    open: boolean
    setOpen: (val: boolean) => void
    addMaster: boolean
    addRestaurant: boolean
    addBranch: boolean
    addAdmin: boolean
    newAdmin: User
    newMaster: User
    newRestaurant: Restaurant
    newBranch: Branch
    handleAdminChange: (field: string, value: any) => void
    handleMasterChange: (field: string, value: any) => void
    handleRestaurantChange: (field: string, value: any) => void
    handleBranchChange: (field: string, value: any) => void
    handleSave: () => void
    licenses: any[]
    openSnackbar: [boolean, boolean, boolean]
}

const CustomModalView: React.FC<Props> = ({
    open,
    setOpen,
    addMaster,
    addRestaurant,
    addBranch,
    addAdmin,
    newAdmin,
    newMaster,
    newRestaurant,
    newBranch,
    handleAdminChange,
    handleMasterChange,
    handleRestaurantChange,
    handleBranchChange,
    handleSave,
    licenses,
    openSnackbar
}) => {
    const classes = useStyles()
    const avoidUser = ['ID', 'Type', 'Status', 'Token', 'BranchID']
    const avoidRestaurant = ['ID', 'MasterID']
    const avoidBranch = ['ID', 'Menu', 'Photo', 'ReservationsTables', 'QueueTables', 'Schedule', 'Status', 'WaitingTime', 'ConsumingTime', 'hasQueue', 'hasReservations', 'Ubication', 'MaxPersonsQueue', 'MasterID', 'RestaurantID', 'Logo']
    const [isHover, setIsHover] = useState<boolean>(false)

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth="md" fullWidth={true}>
            <Grid container className={classes.dialog}>
                {addMaster &&
                    <Grid container className={classes.item}>
                        <Grid container justify="center">
                            <Typography className={classes.title}>Nuevo jefe</Typography>
                        </Grid>
                        <Grid container justify="center">
                            {Object.keys(newMaster).map((key: string) => {
                                // Values that shouldn't be changed
                                if (!avoidUser.includes(key))
                                    return (
                                        <TextField
                                            key={key}
                                            label={key}
                                            variant="outlined"
                                            className={classes.textfield}
                                            value={newMaster[key as 'Name' | 'Phone' | 'Email']}
                                            onChange={e => handleMasterChange(key, e.target.value)}
                                        />
                                    )
                                return null
                            })}
                        </Grid>
                        <Divider className={classes.divider} />
                    </Grid>}

                {addRestaurant &&
                    <Grid container className={classes.item}>
                        <Grid container justify="center">
                            <Typography className={classes.title}>Nuevo restaurante</Typography>
                        </Grid>
                        <Grid container justify="center">
                            {Object.keys(newRestaurant).map((key: string) => {
                                // Values that shouldn't be changed
                                if (!avoidRestaurant.includes(key))
                                    return (
                                        <TextField
                                            key={key}
                                            label={key}
                                            variant="outlined"
                                            className={classes.textfield}
                                            value={newRestaurant[key as 'Name']}
                                            onChange={e => handleRestaurantChange(key, e.target.value)}
                                        />
                                    )
                                return null
                            })}
                        </Grid>
                        <Divider className={classes.divider} />
                    </Grid>}

                {addBranch &&
                    <Grid container className={classes.item}>
                        <Grid container justify="center">
                            <Typography className={classes.title}>Nueva sucursal</Typography>
                        </Grid>
                        <Grid container justify="center">
                            {Object.keys(newBranch).map((key: string) => {
                                // Values that shouldn't be changed
                                if (avoidBranch.includes(key))
                                    return null
                                if (key === 'LicenseID')
                                    return (
                                        <FormControl key={key} variant="outlined" className={classes.textfield}>
                                            <InputLabel>{key}</InputLabel>
                                            <Select
                                                label={key}
                                                value={licenses.find((element) => element.ID === newBranch.LicenseID).ID}
                                                onChange={e => handleBranchChange(key, e.target.value)}
                                            >
                                                {licenses.map(license => {
                                                    return (
                                                        <MenuItem value={license.ID}>{license.Name}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    )
                                if (key === 'Devices')
                                    return (
                                        <TextField
                                            key={key}
                                            label={key}
                                            type='number'
                                            variant="outlined"
                                            className={classes.textfield}
                                            value={newBranch[key as 'Name']}
                                            onChange={e => {
                                                let val = parseInt(e.target.value)
                                                if (val < 0)
                                                    val = 0
                                                handleBranchChange(key, val)
                                            }}
                                        />
                                    )
                                return (
                                    <TextField
                                        key={key}
                                        label={key}
                                        variant="outlined"
                                        className={classes.textfield}
                                        value={newBranch[key as 'Name']}
                                        onChange={e => handleBranchChange(key, e.target.value)}
                                    />
                                )
                            })}
                        </Grid>
                    </Grid>}

                {addAdmin &&
                    <Grid container>
                        <Grid container justify="center">
                            <Typography className={classes.title}>Nuevo administrador</Typography>
                        </Grid>
                        <Grid container justify="center">
                            {Object.keys(newAdmin).map((key: string) => {
                                // Values that shouldn't be changed
                                if (!avoidUser.includes(key))
                                    return (
                                        <TextField
                                            key={key}
                                            label={key}
                                            variant="outlined"
                                            className={classes.textfield}
                                            value={newAdmin[key as 'Name' | 'Phone' | 'Email']}
                                            onChange={e => handleAdminChange(key, e.target.value)}
                                        />
                                    )
                                return null
                            })}
                        </Grid>
                    </Grid>}

            </Grid>

            {/* Response stuff */}
            <Snackbar open={openSnackbar[0]}>
                <Alert severity="success">
                    Guardado exitosamente
                </Alert>
            </Snackbar>

            <Snackbar open={openSnackbar[1]}>
                <Alert severity="error">
                    Algo salió mal.
                </Alert>
            </Snackbar>

            <Snackbar open={openSnackbar[2]}>
                <Alert severity="error">
                    Completa los campos requeridos.
                </Alert>
            </Snackbar>

            <Zoom
                in={true}
                timeout={600}
                style={{
                    transitionDelay: `${100}ms`,
                }}
                unmountOnExit
            >
                <Fab
                    aria-label='add'
                    color="primary"
                    className={classes.fab}
                    variant={isHover ? "extended" : "round"}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    onClick={handleSave}
                >
                    <SaveIcon style={{ marginRight: isHover ? 8 : 0 }} />
                    <label hidden={!isHover}>Guardar cambios</label>
                </Fab>
            </Zoom>

        </Dialog>
    );
}

export default CustomModalView;