import React, { useState } from "react"
import LoginView from './view'
import { signin, sendPasswordResetEmail } from '../../services/auth-service'

const Login: React.FC = () => {
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [openSnackbar, setOpenSnackbar] = useState<Array<boolean>>([false, false, false, false, false])

    const handleSignin = async (e: any) => {
        e.preventDefault()
        let res = await signin(email, password)
            .catch(err => { console.log(err) })

        if (!res || !res.code) {
            window.location.replace("/")
            return
        }

        let array = openSnackbar
        if (res.code === "auth/wrong-password")
            array[1] = true
        else if (res.code === "auth/user-not-found")
            array[2] = true
        else if (res.code === "Unauthorized")
            array[3] = true
        else
            array[4] = true
        setOpenSnackbar([...array])

        setTimeout(() => {
            setOpenSnackbar([false, false, false, false, false])
        }, 5000)
    }

    const sendEmail = async (e: any) => {
        e.preventDefault()
        let res = await sendPasswordResetEmail(email)

        let array = openSnackbar
        if (!res || !res.code)
            array[0] = true
        else if (res.code === "auth/user-not-found")
            array[2] = true
        else
            array[3] = true
        setOpenSnackbar([...array])

        setTimeout(() => {
            setOpenSnackbar([false, false, false, false, false])
        }, 5000)
    }

    return (
        <LoginView
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            handleSignin={handleSignin}
            sendEmail={sendEmail}
            openSnackbar={openSnackbar}
        />
    );
}

export default Login;