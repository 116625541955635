import React, { useState, useEffect } from "react"
import CustomModalView from './view'
import { User, Restaurant, Branch } from '../../values/interfaces'
import { getLicenses, saveUser, saveRestaurant, saveBranch, updateMaster } from '../../services/admin-service'

interface Props {
    open: boolean
    setOpen: (val: boolean) => void
    addMaster: boolean
    addRestaurant: boolean
    addBranch: boolean
    addAdmin: boolean
    newAdmin: User
    setNewAdmin: (user: User) => void
    newMaster: User
    setNewMaster: (user: User) => void
    newRestaurant: Restaurant
    setNewRestaurant: (restaurant: Restaurant) => void
    newBranch: Branch
    setNewBranch: (branch: Branch) => void
    initializeVariables: () => void
}

const CustomModal: React.FC<Props> = ({
    open,
    addMaster,
    addRestaurant,
    addBranch,
    addAdmin,
    setOpen,
    newAdmin,
    setNewAdmin,
    newMaster,
    setNewMaster,
    newRestaurant,
    setNewRestaurant,
    newBranch,
    setNewBranch,
    initializeVariables
}) => {
    const [licenses, setLicenses] = useState<any[]>([])
    const [openSackbar, setOpenSnackbar] = useState<[boolean, boolean, boolean]>([false, false, false])

    useEffect(() => {
        // get licenses
        getLicenses()
            .then(res => {
                setLicenses(res)
            })
            .catch(err => {
                console.log(err)
            })
        // eslint-disable-next-line
    }, [])


    const handleAdminChange = (field: string, value: any) => {
        setNewAdmin({ ...newAdmin, [field]: value } as User)
    }

    const handleMasterChange = (field: string, value: any) => {
        setNewMaster({ ...newMaster, [field]: value } as User)
    }

    const handleRestaurantChange = (field: string, value: any) => {
        setNewRestaurant({ ...newRestaurant, [field]: value } as Restaurant)
    }

    const handleBranchChange = (field: any, value: any) => {
        setNewBranch({ ...newBranch, [field]: value } as Branch)
    }

    const handleSave = async () => {
        if (
            (addBranch && newBranch.Name === '') ||
            (addRestaurant && newRestaurant.Name === '') ||
            (addMaster && (newMaster.Email === '' || newMaster.Name === '')) ||
            (addAdmin && (newAdmin.Email === '' || newAdmin.Name === ''))
        ) {
            setOpenSnackbar([false, false, true])
            setTimeout(() => {
                setOpenSnackbar([false, false, false])
            }, 2000)
            return false
        }

        let masterRes = '', adminRes = '', restaurantRes = '', branchRes = '', band = true

        // if there is a new admin
        if (addAdmin && band) {
            adminRes = await saveUser(newAdmin)
            if (adminRes === '') band = false
        }

        // if there is a new master
        if (addMaster && band) {
            masterRes = await saveUser(newMaster)
            newMaster.ID = masterRes
            if (masterRes === '') band = false
        }

        // if there is a new restaurant
        if (addRestaurant && band) {
            // check if it mastedID needs to be assigned
            if (addMaster)
                newRestaurant.MasterID = masterRes

            restaurantRes = await saveRestaurant(newRestaurant)
            if (restaurantRes === '') band = false
        }

        // if there is a new branch
        if (addBranch && band) {
            // check if it mastedID needs to be assigned
            if (addMaster)
                newBranch.MasterID = masterRes

            // check if it restaurantID needs to be assigned
            if (addRestaurant)
                newBranch.RestaurantID = restaurantRes

            branchRes = await saveBranch(newBranch)
            if (addMaster) {
                newMaster.BranchID = branchRes
                updateMaster(newMaster)
            }

            if (branchRes === '') band = false
        }

        if (band) {
            setOpenSnackbar([true, false, false])
            setTimeout(() => {
                setOpenSnackbar([false, false, false])
                initializeVariables()
                setOpen(false)
            }, 2000)
        } else {
            setOpenSnackbar([false, true, false])
            setTimeout(() => {
                setOpenSnackbar([false, false, false])
            }, 2000)
        }
    }

    return (
        <CustomModalView
            open={open}
            setOpen={setOpen}
            addMaster={addMaster}
            addRestaurant={addRestaurant}
            addBranch={addBranch}
            addAdmin={addAdmin}
            newAdmin={newAdmin}
            newMaster={newMaster}
            newRestaurant={newRestaurant}
            newBranch={newBranch}
            handleAdminChange={handleAdminChange}
            handleMasterChange={handleMasterChange}
            handleRestaurantChange={handleRestaurantChange}
            handleBranchChange={handleBranchChange}
            handleSave={handleSave}
            licenses={licenses}
            openSnackbar={openSackbar}
        />
    );
}

export default CustomModal;