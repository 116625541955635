import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { User, Restaurant, Branch } from '../values/interfaces';
import { createUser, sendPasswordResetEmail } from './auth-service'

const db = firebase.firestore();

export const getMasters = async () => {
    return await db.collection('Users')
        .where("Type", "==", 4)
        .get()
        .then(async documents => {
            if (documents.empty)
                return [];
            let users: User[] = []
            documents.forEach(doc => {
                let newUser = doc.data() as User
                newUser.ID = doc.id
                users.push(newUser)
            })
            return users
        })
        .catch(err => {
            console.log(err)
            return []
        })
}

export const getRestaurants = async (masterID?: string) => {
    // console.log(masterID)
    let ref = db.collection('Restaurants')
    // if (masterID)
    //     ref.where('MasterID', '==', masterID)
    return await ref.get()
        .then(async documents => {
            if (documents.empty)
                return [];
            let restaurants: Restaurant[] = []
            documents.forEach(doc => {
                let newRestaurant = doc.data() as Restaurant
                newRestaurant.ID = doc.id
                restaurants.push(newRestaurant)
            })
            return restaurants
        })
        .catch(err => {
            console.log(err)
            return []
        })
}

export const getBranches = async (restaurantID?: string) => {
    // console.log(restaurantID)
    let ref = db.collection('Branches')
    // if (restaurantID)
    //     ref.where('RestaurantID', '==', restaurantID)
    return await ref.get()
        .then(async documents => {
            if (documents.empty)
                return [];
            let branches: Branch[] = []
            documents.forEach(doc => {
                let newBranch = doc.data() as Branch
                newBranch.ID = doc.id
                branches.push(newBranch)
            })
            return branches
        })
        .catch(err => {
            console.log(err)
            return []
        })
}

export const getLicenses = async () => {
    return await db.collection('Licenses')
        .get()
        .then(async documents => {
            if (documents.empty)
                return [];
            let licenses: any[] = []
            documents.forEach(doc => {
                let newLicense = doc.data()
                newLicense['ID'] = doc.id
                licenses.push(newLicense)
            })
            return licenses
        })
        .catch(err => {
            console.log(err)
            return []
        })
}

export const saveUser = async (user: User) => {
    let authUser = await createUser(user.Email, "reservapp")
    if (!authUser)
        return ''
    let authEmail = await sendPasswordResetEmail(user.Email)
    if (authEmail.code !== undefined)
        return ''
    return await db.collection('Users')
        .add(user)
        .then((doc) => doc.id)
        .catch((err) => {
            console.log(err)
            return ''
        })
}

export const updateMaster = async (user: User) => {
    if (user.ID !== "")
        return await db.collection('Users')
            .doc(user.ID)
            .set(user)
            .then(() => true)
            .catch(() => false)
    return false
}

export const saveRestaurant = async (restaurant: Restaurant) => {
    let doc = db.collection('Restaurants').doc()
    // set restaurant id to object
    restaurant.ID = doc.id
    return await doc.set(restaurant)
        .then(() => doc.id)
        .catch((err) => {
            console.log(err)
            return ''
        })
}

export const saveBranch = async (branch: Branch) => {
    let ref = db.collection('Branches').doc()
    // Set branch id to object
    branch.ID = ref.id
    return await ref.set(branch)
        .then(() => ref.id)
        .catch((err) => {
            console.log(err)
            return ''
        })
}

export const getBranchAnalytics = async (branchID: string, date: string) => {
    return await db.collection('Branches')
        .doc(branchID)
        .collection("Analytics")
        .doc(date)
        .get()
        .then(res => res.data() ? res.data() : null)
        .catch(err => {
            console.log(err)
            return null
        })
}

export const removeMaster = async (master: User) => {
    return await db.collection('Users')
        .doc(master.ID)
        .delete()
        .then(async () => {
            return await removeRestaurant(master.ID, null)
        })
        .catch(err => {
            console.log(err)
            return false
        })
}

export const removeRestaurant = async (masterID: string | null, restaurant: Restaurant | null) => {
    let ref = db.collection('Restaurants')

    if (masterID) {
        (await ref.where("MasterID", "==", masterID)
            .get()).forEach(doc => {
                ref.doc(doc.id)
                    .delete()
            })

        let res = await removeBranch(masterID, null, null)
        console.log(res)
        return res
    }

    if (restaurant) {
        return await ref.doc(restaurant.ID)
            .delete()
            .then(async () => {
                let res = await removeBranch(null, restaurant.ID, null)
                console.log(res)
                return res
            })
            .catch(err => {
                console.log(err)
                return false
            })
    }
}

export const removeBranch = async (masterID: string | null, restaurantID: string | null, branch: Branch | null) => {
    let ref = db.collection('Branches')
    let docsRef;
    if (masterID)
        docsRef = ref.where("MasterID", "==", masterID)
    else if (restaurantID)
        docsRef = ref.where("RestaurantID", "==", restaurantID)

    if (docsRef) {
        (await docsRef.get()).forEach(doc => ref.doc(doc.id).delete())
        return true
    }

    if (branch) {
        return await ref.doc(branch.ID)
            .delete()
            .then(() => true)
            .catch(err => {
                console.log(err)
                return false
            })
    }
}