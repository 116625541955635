import React, { useState, useEffect } from "react";
import MainView from './view';
import firebase from '../../services/firebase'
import { getMasters, getRestaurants, getBranches } from '../../services/admin-service';
import { signout } from '../../services/auth-service'
import { User, Restaurant, Branch } from '../../values/interfaces'

const Main: React.FC = () => {
    const [users, setUsers] = useState<User[]>([])
    const [restaurants, setRestaurants] = useState<Restaurant[]>([])
    const [branches, setBranches] = useState<Branch[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    const [selectedMaster, setSelectedMaster] = useState<User | null>(null);
    const [selectedRestaurant, setSelectedRestaurant] = useState<Restaurant | null>(null);
    const [selectedBranch, setSelectedBranch] = useState<Branch | null>(null);

    const [newAdmin, setNewAdmin] = useState<User>({} as User)
    const [newMaster, setNewMaster] = useState<User>({} as User)
    const [newRestaurant, setNewRestaurant] = useState<Restaurant>({} as Restaurant)
    const [newBranch, setNewBranch] = useState<Branch>({} as Branch)

    useEffect(() => {
        // Check if user is signed in
        firebase.auth().onAuthStateChanged(
            authUser => {
                if (!authUser) {
                    window.location.replace('/Login')
                    return
                }
                setLoading(false)
            }
        );

        initializeVariables()

        // Get masters
        getUsers()
        // Get all restaurants
        getRestaurants()
            .then(res => {
                setRestaurants(res)
            })
            .catch(err => {
                console.log(err)
            })
        // Get all branches
        getBranches()
            .then(res => {
                setBranches(res)
            })
            .catch(err => {
                console.log(err)
            })

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (selectedMaster) {
            setNewRestaurant({ ...newRestaurant, 'MasterID': selectedMaster?.ID } as Restaurant)
            setNewBranch({ ...newBranch, 'MasterID': selectedMaster?.ID } as Branch)
        }
        setSelectedRestaurant(null)

        // eslint-disable-next-line
    }, [selectedMaster])

    useEffect(() => {
        if (selectedRestaurant)
            setNewBranch({ ...newBranch, 'RestaurantID': selectedRestaurant.ID, 'MasterID': selectedRestaurant.MasterID } as Branch)
        setSelectedBranch(null)

        // eslint-disable-next-line
    }, [selectedRestaurant])

    const getUsers = () => {
        getMasters()
            .then(res => {
                setUsers([...res])
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getMasterRestaurants = async () => {
        const restaurants = await getRestaurants()
        setRestaurants([...restaurants])
    }

    const getRestaurantBranches = async () => {
        const branches = await getBranches()
        setBranches([...branches])
    }

    const initializeVariables = () => {
        let admin: User = {
            ID: '',
            Email: '',
            Name: '',
            Phone: '',
            Status: true,
            Type: 5,
            Token: ''
        } as User

        let master: User = {
            ID: '',
            Email: '',
            Name: '',
            Phone: '',
            Status: true,
            Type: 4,
            Token: '',
            RFC: '',
            Domicilio: '',
            RazonSocial: ''
        } as User

        let restaurant: Restaurant = {
            ID: '',
            Name: '',
            MasterID: selectedMaster ? selectedMaster : ""
        } as Restaurant

        let branch: Branch = {
            Description: '',
            ID: '',
            LicenseID: 'bJ4b2d2J3x3BbBjnUOD8',
            Logo: '',
            Menu: [] as string[],
            Name: '',
            Phone: '',
            Photo: '',
            Ubication: {},
            ConsumingTime: 60,
            Devices: 1,
            MasterID: selectedMaster ? selectedMaster : "",
            MaxPersonsQueue: 4,
            QueueTables: ["", "", "", "", ""],
            ReservationsTables: {
                'Sección 1': {
                    '4': ["", "", "", "", ""],
                    '6': ["", "", ""]
                }
            },
            RestaurantID: selectedRestaurant ? selectedRestaurant : '',
            Schedule: {
                Monday: {
                    Open: "08:00",
                    Close: "22:00"
                },
                Tuesday: {
                    Open: "08:00",
                    Close: "22:00"
                },
                Wednesday: {
                    Open: "08:00",
                    Close: "22:00"
                },
                Thursday: {
                    Open: "08:00",
                    Close: "22:00"
                },
                Friday: {
                    Open: "08:00",
                    Close: "22:00"
                },
                Saturday: {
                    Open: "08:00",
                    Close: "14:00"
                },
                Sunday: {
                    Open: "00:00",
                    Close: "00:00"
                }
            },
            Status: true,
            WaitingTime: 20,
            hasQueue: true,
            hasReservations: true
        } as Branch

        setNewAdmin(admin)
        setNewMaster(master)
        setNewRestaurant(restaurant)
        setNewBranch(branch)
    }

    const handleSignOut = (e: any) => {
        e.preventDefault()
        signout()
        window.location.replace('/Login')
    }

    return (
        <MainView
            loading={loading}
            getUsers={getUsers}
            setUsers={setUsers}
            users={users}
            getRestaurants={getMasterRestaurants}
            setRestaurants={setRestaurants}
            restaurants={restaurants}
            branches={branches}
            getBranches={getRestaurantBranches}
            setBranches={setBranches}
            handleSignOut={handleSignOut}

            selectedMaster={selectedMaster}
            setSelectedMaster={setSelectedMaster}
            selectedRestaurant={selectedRestaurant}
            setSelectedRestaurant={setSelectedRestaurant}
            selectedBranch={selectedBranch}
            setSelectedBranch={setSelectedBranch}

            newAdmin={newAdmin}
            setNewAdmin={setNewAdmin}
            newMaster={newMaster}
            setNewMaster={setNewMaster}
            newRestaurant={newRestaurant}
            setNewRestaurant={setNewRestaurant}
            newBranch={newBranch}
            setNewBranch={setNewBranch}
            initializeVariables={initializeVariables}
        />
    );
}

export default Main;