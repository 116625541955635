import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: 16,
        height: "max-content",
        overflow: "auto"
    },
    item: {
        marginBottom: 16
    },
    textfield: {
        margin: 8,
        flexGrow: 1,
        minWidth: 200,
        maxWidth: 300
    },
    title: {
        fontWeight: "bold",
        marginBottom: 8,
        fontSize: "large",
        color: "#62bd6e"
    },
    divider: {
        color: "B5B5B5",
        width: "100%",
        marginTop: 16
    },
    fab: {
        position: 'absolute',
        bottom: 16,
        right: 32
    },
}));