import firebase, { secondary } from './firebase';

const auth = firebase.auth();

export const signin = async (email: string, password: string) => {
    return await auth.signInWithEmailAndPassword(email, password)
        .then(async () => {
            // get firestore user
            return await firebase.firestore().collection('Users')
                .where("Email", "==", email)
                .limit(1)
                .get()
                .then(res => {
                    if (res.empty) {
                        auth.signOut()
                        return { code: 'Unauthorized' }
                    }
                    let type = res.docs[0].get("Type")
                    if (type !== 5) {
                        auth.signOut()
                        return { code: 'Unauthorized' }
                    }
                    return true
                })
        })
        .catch(err => err)
}

export const sendPasswordResetEmail = async (email: string) => {
    return await auth.sendPasswordResetEmail(email)
        .then(() => true)
        .catch(err => err)
}

export const signout = async () => {
    return await auth.signOut()
        .then(() => true)
        .catch(err => err)
}

export const createUser = async (email: string, password: string) => {
    return await secondary.createUserWithEmailAndPassword(email, password)
        .then(() => {
            secondary.signOut()
            return true
        })
        .catch(() => false)
}