import React, { useState, useEffect } from "react"
import { useStyles } from './style'
import { User, Restaurant, Branch } from '../../values/interfaces'
import CustomModal from '../CustomModal/index'
import { Chart } from '@antv/g2';

// Material UI
import {
    Grid,
    Card,
    Divider,
    Typography,
    Button,
    Zoom,
    Fab,
    TextField,
    Drawer,
    Popover,
    Avatar
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import AddIcon from '@material-ui/icons/Add';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CloseIcon from '@material-ui/icons/Close';
import PieChartIcon from '@material-ui/icons/PieChart';
import DescriptionIcon from '@material-ui/icons/Description';
import { removeMaster, removeRestaurant, removeBranch, getBranchAnalytics } from "../../services/admin-service";

interface Props {
    loading: boolean
    getUsers: () => void
    setUsers: (users: User[]) => void
    users: User[]
    getRestaurants: () => void
    setRestaurants: (restaurants: Restaurant[]) => void
    restaurants: Restaurant[]
    branches: Branch[]
    getBranches: () => void
    setBranches: (branches: Branch[]) => void
    handleSignOut: (event: any) => void

    selectedMaster: User | null
    setSelectedMaster: (master: User | null) => void
    selectedRestaurant: Restaurant | null
    setSelectedRestaurant: (restaurant: Restaurant | null) => void
    selectedBranch: Branch | null
    setSelectedBranch: (branch: Branch | null) => void


    newAdmin: User
    setNewAdmin: (user: User) => void
    newMaster: User
    setNewMaster: (user: User) => void
    newRestaurant: Restaurant
    setNewRestaurant: (restaurant: Restaurant) => void
    newBranch: Branch
    setNewBranch: (branch: Branch) => void
    initializeVariables: () => void
}

const MainView: React.FC<Props> = ({
    loading,
    getUsers,
    setUsers,
    users,
    getRestaurants,
    setRestaurants,
    restaurants,
    branches,
    getBranches,
    setBranches,
    handleSignOut,
    selectedMaster,
    setSelectedMaster,
    selectedRestaurant,
    setSelectedRestaurant,
    selectedBranch,
    setSelectedBranch,
    newAdmin,
    setNewAdmin,
    newMaster,
    setNewMaster,
    newRestaurant,
    setNewRestaurant,
    newBranch,
    setNewBranch,
    initializeVariables
}) => {
    const classes = useStyles()
    const [analyticsData, setAnalyticsData] = useState<any>(null)
    const [mastersFilter, setMastersFilter] = useState<string>('')
    const [restaurantsFilter, setRestaurantsFilter] = useState<string>('')
    const [branchesFilter, setBranchesFilter] = useState<string>('')
    const [isHover, setIsHover] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [addMaster, setAddMaster] = useState<boolean>(false);
    const [addRestaurant, setAddRestaurant] = useState<boolean>(false);
    const [addBranch, setAddBranch] = useState<boolean>(false);
    const [addAdmin, setAddAdmin] = useState<boolean>(false);
    const [openAnalytics, setOpenAnalytics] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<string>("");
    const [hoverAnalytics, setHoverAnalytics] = useState<boolean>(false);
    const [openRemoveMaster, setOpenRemoveMaster] = useState<number>(-1);
    const [masterAnchor, setMasterAnchor] = useState<null | HTMLElement>(null);
    const [openRemoveRestaurant, setOpenRemoveRestaurant] = useState<number>(-1);
    const [restaurantAnchor, setRestaurantAnchor] = useState<null | HTMLElement>(null);
    const [openRemoveBranch, setOpenRemoveBranch] = useState<number>(-1);
    const [branchAnchor, setBranchAnchor] = useState<null | HTMLElement>(null);

    useEffect(() => {
        if (openModal)
            return

        if (addMaster) {
            setAddMaster(false)
            getUsers()
        }

        if (addRestaurant) {
            setAddRestaurant(false)
            getRestaurants()
        }

        if (addBranch) {
            setAddBranch(false)
            getBranches()
        }
        if (addAdmin)
            setAddAdmin(false)

        // eslint-disable-next-line
    }, [openModal])

    useEffect(() => {
        if (selectedBranch) {
            let date = new Date();
            let year = date.getFullYear();
            let month: number | string = date.getMonth();
            if (month < 10)
                month = '0' + month;
            let selectedDate = `${year}-${month}`;
            getAnalytics(selectedBranch.ID, selectedDate);
            setOpenAnalytics(true);
            setSelectedDate(selectedDate);
        }
    }, [selectedBranch])

    const getAnalytics = async (branchID: string, date: string) => {
        let analytics = await getBranchAnalytics(branchID, date)
        setAnalyticsData(analytics)
    }

    const handleAddMaster = () => {
        setAddMaster(true)
        setAddRestaurant(true)
        setAddBranch(true)
        setOpenModal(true)
    }

    const handleAddRestaurant = () => {
        setAddRestaurant(true)
        setAddBranch(true)
        setOpenModal(true)
    }

    const handleAddBranch = () => {
        setAddBranch(true)
        setOpenModal(true)
    }

    const handleAddAdmin = () => {
        setAddAdmin(true)
        setOpenModal(true)
    }

    const handleDeleteMaster = async (master: User) => {
        let res = await removeMaster(master)
        setSelectedMaster(null)
        setSelectedRestaurant(null)
        setSelectedBranch(null)
        console.log(res)
        // get infooooooooooo
        setUsers(users.filter(l => l.ID !== master.ID))
        setRestaurants(restaurants.filter(l => l.MasterID !== master.ID))
        setBranches(branches.filter(l => l.MasterID !== master.ID))
        setOpenRemoveMaster(-1)
        setMasterAnchor(null)
    }

    const handleDeleteRestaurant = async (restaurant: Restaurant) => {
        let res = await removeRestaurant(null, restaurant)
        setSelectedMaster(null)
        setSelectedRestaurant(null)
        setSelectedBranch(null)
        console.log(res)
        // get info
        setRestaurants(restaurants.filter(l => l.ID !== restaurant.ID))
        setBranches(branches.filter(l => l.RestaurantID !== restaurant.ID))
        setOpenRemoveRestaurant(-1)
        setRestaurantAnchor(null)
    }

    const handleDeleteBranch = async (branch: Branch) => {
        let res = await removeBranch(null, null, branch)
        setSelectedMaster(null)
        setSelectedRestaurant(null)
        setSelectedBranch(null)
        console.log(res)
        // get info
        setBranches(branches.filter(l => l.ID !== branch.ID))
        setOpenRemoveBranch(-1)
        setBranchAnchor(null)
    }

    const CustomSkeleton = () => {
        return (
            <Grid container>

                <Grid container style={{ height: "90%", margin: "auto" }}>

                    <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Grid container>
                            <div className={classes.titleDiv}>
                                <Skeleton variant="text" style={{ width: "50%", height: "100%" }} />
                            </div>
                            <Grid container justify="center" className={classes.card} style={{ border: 'none', marginLeft: 32, marginRight: 4, height: 300 }} >
                                <Skeleton variant="rect" style={{ height: "100%", width: "100%" }} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Grid container>
                            <div className={classes.titleDiv}>
                                <Skeleton variant="text" style={{ width: "50%", height: "100%" }} />
                            </div>
                            <Grid container justify="center" className={classes.card} style={{ border: 'none', marginRight: 4, height: 300 }} >
                                <Skeleton variant="rect" style={{ height: "100%", width: "100%" }} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Grid container>
                            <div className={classes.titleDiv}>
                                <Skeleton variant="text" style={{ width: "50%", height: "100%" }} />
                            </div>
                            <Grid container justify="center" className={classes.card} style={{ border: 'none', marginRight: 32, height: 300 }} >
                                <Skeleton variant="rect" style={{ height: "100%", width: "100%" }} />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        )
    }

    /*     const loadReservationsChart = () => {
            if (!analyticsData)
                return
            // load data format
            let obj = analyticsData['Reservations']
            if (!obj)
                return
            let keys = Object.keys(obj)
            let cont = 0;
            let data: any[] = []
            keys.forEach(key => {
                cont += obj[key]
            })
            keys.forEach(key => {
                data.push({ item: key, count: obj[key], percent: (obj[key] / cont) })
            })
    
            // empty div
            var div = document.getElementById('reservationsChart');
            if (!div) {
                console.log('isnull')
                return
            }
            while (div.firstChild) {
                div.removeChild(div.firstChild);
            }
    
            // load chart
            const chart = new Chart({
                container: 'reservationsChart',
                autoFit: true,
                height: 500,
            });
            chart.data(data);
            chart.scale('percent', {
                formatter: (val: any) => {
                    val = val * 100 + '%';
                    return val;
                },
            });
            chart.coordinate('theta', {
                radius: 0.75,
                innerRadius: 0.6,
            });
            chart.tooltip({
                showTitle: false,
                showMarkers: false,
                itemTpl: '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>',
            });
            // 辅助文本
            chart
                .annotation()
                .text({
                    position: ['50%', '50%'],
                    content: '主机',
                    style: {
                        fontSize: 14,
                        fill: '#8c8c8c',
                        textAlign: 'center',
                    },
                    offsetY: -20,
                })
                .text({
                    position: ['50%', '50%'],
                    content: '200',
                    style: {
                        fontSize: 20,
                        fill: '#8c8c8c',
                        textAlign: 'center',
                    },
                    offsetX: -10,
                    offsetY: 20,
                })
                .text({
                    position: ['50%', '50%'],
                    content: '台',
                    style: {
                        fontSize: 14,
                        fill: '#8c8c8c',
                        textAlign: 'center',
                    },
                    offsetY: 20,
                    offsetX: 20,
                });
            chart
                .interval()
                .adjust('stack')
                .position('percent')
                .color('item')
                .label('percent', (percent: any) => {
                    return {
                        content: (data: any) => {
                            return `${data.item}: ${percent * 100}%`;
                        },
                    };
                })
                .tooltip('item*percent', (item: any, percent: any) => {
                    percent = percent * 100 + '%';
                    return {
                        name: item,
                        value: percent,
                    };
                });
    
            chart.interaction('element-active');
    
            chart.render();
        } */

    const loadChart = (key: 'Reservations' | 'Queue') => {
        if (!analyticsData)
            return
        // load data format
        let obj = analyticsData[key]
        if (!obj)
            return
        let keys = Object.keys(obj)
        let cont = 0;
        let data: any[] = []
        keys.forEach(key => {
            cont += obj[key]
        })
        keys.forEach(key => {
            data.push({ item: key, count: obj[key], percent: (obj[key] / cont) })
        })

        // empty div
        var div = document.getElementById(key + 'Chart');
        if (!div)
            return
        while (div.firstChild) {
            div.removeChild(div.firstChild);
        }

        // load chart
        const chart = new Chart({
            container: key + 'Chart',
            // autoFit: true,
            height: 400,
            width: 400,
        });
        chart.data(data);
        chart.scale('percent', {
            formatter: (val: any) => {
                val = val * 100 + '%';
                return 'val';
            },
        });
        chart.coordinate('theta', {
            radius: 0.75,
            // innerRadius: 0.6,
        });
        chart.tooltip({
            showTitle: false,
            showMarkers: false,
            itemTpl: '<li class="g2-tooltip-list-item"><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value} - ({percent})</li>',
        });
        // 辅助文本
        // chart
        //     .annotation()
        //     .text({
        //         position: ['50%', '50%'],
        //         content: '主机',
        //         style: {
        //             fontSize: 14,
        //             fill: '#8c8c8c',
        //             textAlign: 'center',
        //         },
        //         offsetY: -20,
        //     })
        //     .text({
        //         position: ['50%', '50%'],
        //         content: '200',
        //         style: {
        //             fontSize: 20,
        //             fill: '#8c8c8c',
        //             textAlign: 'center',
        //         },
        //         offsetX: -10,
        //         offsetY: 20,
        //     })
        //     .text({
        //         position: ['50%', '50%'],
        //         content: '台',
        //         style: {
        //             fontSize: 14,
        //             fill: '#8c8c8c',
        //             textAlign: 'center',
        //         },
        //         offsetY: 20,
        //         offsetX: 20,
        //     });
        chart
            .interval()
            .adjust('stack')
            .position('percent')
            .color('item')
            .tooltip('item*count*percent', (item: any, count: any, percent: any) => {
                percent = percent * 100 + '%';
                return {
                    name: item,
                    value: count,
                    percent: percent,
                };
            })
            .style({ margin: 'auto' });

        chart.interaction('element-active');

        chart.render();
    }

    return (
        <Grid container justify="center" alignContent="center" className={classes.root}>

            {loading ?
                <CustomSkeleton />
                :
                <Grid container className={classes.root}>

                    <Grid container justify="flex-end" style={{ height: "min-content" }}>
                        <img alt="logo" src="Logo reservApp Verde.png" height={24} style={{ margin: "auto", marginLeft: 16 }} />
                        <Button color="primary" onClick={handleSignOut}>Cerrar sesión</Button>
                    </Grid>

                    <Grid container style={{ height: "90%", margin: "auto" }}>

                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Grid container style={{ paddingLeft: 32, height: "90%" }}>

                                <div className={classes.titleDiv}>
                                    <Typography className={classes.cardTitle}>Masters</Typography>
                                </div>

                                <div className={classes.searchDiv}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Buscar..."
                                        className={classes.search}
                                        value={mastersFilter}
                                        onChange={e => setMastersFilter(e.target.value)}
                                    />
                                </div>

                                <Grid container justify="center" style={{ height: "90%" }}>
                                    <Card className={classes.card} elevation={0}>
                                        <Grid container justify="flex-end" style={{ height: "100%", position: 'relative' }}>

                                            <Grid container alignContent="flex-start">
                                                {users.filter
                                                    (element =>
                                                        mastersFilter.length > 0 ? element.Name.toLocaleLowerCase().includes(mastersFilter.toLocaleLowerCase()) ||
                                                            element.Email.toLocaleLowerCase().includes(mastersFilter.toLocaleLowerCase())
                                                            :
                                                            true
                                                    )
                                                    .map((user, masterIndex) => {

                                                        return (
                                                            <Grid
                                                                container
                                                                key={user.Email}
                                                                className={
                                                                    user.ID === selectedMaster?.ID || user.ID === selectedRestaurant?.MasterID || user.ID === selectedBranch?.MasterID ?
                                                                        classes.itemSelected :
                                                                        classes.item
                                                                }
                                                                onClick={() => selectedMaster?.ID === user.ID ? setSelectedMaster(null) : setSelectedMaster(user)}
                                                            >
                                                                <Grid container>
                                                                    <Typography className={classes.title}>{user.RazonSocial ? user.RazonSocial : user.Name}</Typography>
                                                                    <CloseIcon
                                                                        fontSize="small"
                                                                        className={user.ID === selectedMaster?.ID || user.ID === selectedRestaurant?.MasterID || user.ID === selectedBranch?.MasterID ? classes.closeIconSelected : classes.closeIcon}
                                                                        onClick={(e: any) => {
                                                                            setOpenRemoveMaster(masterIndex)
                                                                            setMasterAnchor(e.target)
                                                                        }}
                                                                    />
                                                                    <Popover
                                                                        open={openRemoveMaster === masterIndex}
                                                                        anchorEl={masterAnchor}
                                                                        onClose={() => {
                                                                            setOpenRemoveMaster(-1)
                                                                            setMasterAnchor(null)
                                                                        }}
                                                                    >
                                                                        <Card style={{ padding: 16 }}>
                                                                            <Grid container>
                                                                                <Typography style={{ marginBottom: 8 }}>{"¿Estás seguro que deseas borrar a " + user.Name + "?"}</Typography>
                                                                            </Grid>
                                                                            <Grid container>
                                                                                <Grid item md={6}>
                                                                                    <Button variant="outlined" color="primary" className={classes.removeItemButton} onClick={() => {
                                                                                        setOpenRemoveMaster(-1)
                                                                                        setMasterAnchor(null)
                                                                                    }}>Cancelar</Button>
                                                                                </Grid>
                                                                                <Grid item md={6}>
                                                                                    <Button variant="contained" color="primary" className={classes.removeItemButton} onClick={() => handleDeleteMaster(user)}>Borrar</Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Card>
                                                                    </Popover>
                                                                </Grid>
                                                                <Grid container>
                                                                    <Typography className={classes.subtitle}>{user.RFC ? user.RFC : user.Email}</Typography>
                                                                </Grid>
                                                                <Grid container>
                                                                    <Typography className={classes.subtitle}>{user.Domicilio ? user.Domicilio : ""}</Typography>
                                                                </Grid>
                                                                <Grid container>
                                                                    <Divider className={classes.divider} />
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })}
                                            </Grid>

                                            <Zoom
                                                in={true}
                                                timeout={600}
                                                style={{
                                                    transitionDelay: `${100}ms`,
                                                }}
                                                unmountOnExit
                                            >
                                                <Fab aria-label='add' color="primary" className={classes.fab} onClick={handleAddMaster}>
                                                    <AddIcon />
                                                </Fab>
                                            </Zoom>

                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Grid container style={{ height: "90%" }}>

                                <div className={classes.titleDiv}>
                                    <Typography className={classes.cardTitle}>Restaurantes</Typography>
                                </div>

                                <div className={classes.searchDiv}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Buscar..."
                                        className={classes.search}
                                        value={restaurantsFilter}
                                        onChange={e => setRestaurantsFilter(e.target.value)}
                                    />
                                </div>

                                <Grid container justify="center" style={{ height: "90%" }}>
                                    <Card className={classes.card} elevation={0}>
                                        <Grid container justify="flex-end" style={{ height: "100%", position: 'relative' }}>

                                            <Grid container alignContent="flex-start">
                                                {restaurants.filter(
                                                    element =>
                                                        restaurantsFilter.length > 0 ?
                                                            selectedMaster ?
                                                                (element.MasterID === selectedMaster?.ID &&
                                                                    element.Name.toLocaleLowerCase().includes(restaurantsFilter.toLocaleLowerCase()))
                                                                :
                                                                element.Name.toLocaleLowerCase().includes(restaurantsFilter.toLocaleLowerCase())
                                                            :
                                                            selectedMaster ?
                                                                element.MasterID === selectedMaster?.ID
                                                                :
                                                                true)
                                                    .map((restaurant, restaurantIndex) => {

                                                        return (
                                                            <Grid
                                                                container
                                                                key={restaurant.ID}
                                                                className={
                                                                    restaurant.ID === selectedRestaurant?.ID || restaurant.ID === selectedBranch?.RestaurantID ?
                                                                        classes.itemSelected :
                                                                        classes.item
                                                                }
                                                                onClick={() => selectedRestaurant?.ID === restaurant.ID ? setSelectedRestaurant(null) : setSelectedRestaurant(restaurant)}
                                                            >
                                                                <Grid container>
                                                                    <Typography className={classes.title}>{restaurant.Name}</Typography>
                                                                    <CloseIcon
                                                                        fontSize="small"
                                                                        className={restaurant.ID === selectedRestaurant?.ID || restaurant.ID === selectedBranch?.RestaurantID ? classes.closeIconSelected : classes.closeIcon}
                                                                        onClick={(e: any) => {
                                                                            setOpenRemoveRestaurant(restaurantIndex)
                                                                            setRestaurantAnchor(e.target)
                                                                        }}
                                                                    />
                                                                    <Popover
                                                                        open={openRemoveRestaurant === restaurantIndex}
                                                                        anchorEl={restaurantAnchor}
                                                                        onClose={() => {
                                                                            setOpenRemoveRestaurant(-1)
                                                                            setRestaurantAnchor(null)
                                                                        }}
                                                                    >
                                                                        <Card style={{ padding: 16 }}>
                                                                            <Grid container>
                                                                                <Typography style={{ marginBottom: 8 }}>{"¿Estás seguro que deseas borrar al restaurante " + restaurant.Name + "?"}</Typography>
                                                                            </Grid>
                                                                            <Grid container>
                                                                                <Grid item md={6}>
                                                                                    <Button variant="outlined" color="primary" className={classes.removeItemButton} onClick={() => {
                                                                                        setOpenRemoveRestaurant(-1)
                                                                                        setRestaurantAnchor(null)
                                                                                    }}>Cancelar</Button>
                                                                                </Grid>
                                                                                <Grid item md={6}>
                                                                                    <Button variant="contained" color="primary" className={classes.removeItemButton} onClick={() => handleDeleteRestaurant(restaurant)}>Borrar</Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Card>
                                                                    </Popover>
                                                                </Grid>
                                                                <Grid container>
                                                                    <Divider className={classes.divider} />
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })}
                                            </Grid>

                                            <Zoom
                                                in={selectedMaster !== null}
                                                timeout={600}
                                                style={{
                                                    transitionDelay: `${100}ms`,
                                                }}
                                                unmountOnExit
                                            >
                                                <Fab aria-label='add' color="primary" className={classes.fab} onClick={handleAddRestaurant}>
                                                    <AddIcon />
                                                </Fab>
                                            </Zoom>

                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Grid container style={{ paddingRight: 32, height: "90%" }}>

                                <div className={classes.titleDiv}>
                                    <Typography className={classes.cardTitle}>Sucursales</Typography>
                                </div>

                                <div className={classes.searchDiv} style={{ marginRight: 32 }}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Buscar..."
                                        className={classes.search}
                                        value={branchesFilter}
                                        onChange={e => setBranchesFilter(e.target.value)}
                                    />
                                </div>

                                <Grid container justify="center" style={{ height: "90%" }}>
                                    <Card className={classes.card} style={{ marginRight: 32 }} elevation={0}>
                                        <Grid container justify="flex-end" style={{ height: "100%", position: 'relative' }}>

                                            <Grid container alignContent="flex-start">
                                                {branches.filter(
                                                    element =>
                                                        branchesFilter.length > 0 ?
                                                            selectedRestaurant ?
                                                                (element.RestaurantID === selectedRestaurant?.ID &&
                                                                    element.Name.toLocaleLowerCase().includes(branchesFilter.toLocaleLowerCase()))
                                                                :
                                                                selectedMaster ?
                                                                    (element.MasterID === selectedMaster?.ID &&
                                                                        element.Name.toLocaleLowerCase().includes(branchesFilter.toLocaleLowerCase()))
                                                                    :
                                                                    element.Name.toLocaleLowerCase().includes(branchesFilter.toLocaleLowerCase())
                                                            :
                                                            selectedRestaurant ?
                                                                element.RestaurantID === selectedRestaurant.ID
                                                                :
                                                                selectedMaster ?
                                                                    element.MasterID === selectedMaster?.ID
                                                                    :
                                                                    true)
                                                    .map((branch, branchIndex) => {
                                                        return (
                                                            <Grid
                                                                container
                                                                key={branch.ID}
                                                                className={
                                                                    branch.ID === selectedBranch?.ID ?
                                                                        classes.itemSelected :
                                                                        classes.item
                                                                }
                                                                onClick={() => selectedBranch?.ID === branch.ID ? setSelectedBranch(null) : setSelectedBranch(branch)}
                                                            >
                                                                <Grid container alignContent="center">
                                                                    <Typography className={classes.title}>{branch.Name}</Typography>
                                                                    <CloseIcon
                                                                        fontSize="small"
                                                                        className={branch.ID === selectedBranch?.ID ? classes.closeIconSelected : classes.closeIcon}
                                                                        onClick={(e: any) => {
                                                                            setOpenRemoveBranch(branchIndex)
                                                                            setBranchAnchor(e.target)
                                                                        }}
                                                                    />
                                                                    <Popover
                                                                        open={openRemoveBranch === branchIndex}
                                                                        anchorEl={branchAnchor}
                                                                        onClose={() => {
                                                                            setOpenRemoveBranch(-1)
                                                                            setBranchAnchor(null)
                                                                        }}
                                                                    >
                                                                        <Card style={{ padding: 16 }}>
                                                                            <Grid container>
                                                                                <Typography style={{ marginBottom: 8 }}>{"¿Estás seguro que deseas borrar la sucursal " + branch.Name + "?"}</Typography>
                                                                            </Grid>
                                                                            <Grid container>
                                                                                <Grid item md={6}>
                                                                                    <Button variant="outlined" color="primary" className={classes.removeItemButton} onClick={() => {
                                                                                        setOpenRemoveBranch(-1)
                                                                                        setBranchAnchor(null)
                                                                                    }}>Cancelar</Button>
                                                                                </Grid>
                                                                                <Grid item md={6}>
                                                                                    <Button variant="contained" color="primary" className={classes.removeItemButton} onClick={() => handleDeleteBranch(branch)}>Borrar</Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Card>
                                                                    </Popover>
                                                                </Grid>
                                                                <Grid container>
                                                                    <Divider className={classes.divider} />
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    })}
                                            </Grid>

                                            <Zoom
                                                in={selectedBranch !== null}
                                                timeout={600}
                                                style={{
                                                    transitionDelay: `${100}ms`,
                                                }}
                                                unmountOnExit
                                            >
                                                <div style={{ height: 'min-content', display: 'flex' }}>
                                                    <Typography hidden={!hoverAnalytics} style={{ position: 'absolute', bottom: selectedRestaurant ? 95 : 30, right: 80, fontWeight: 'bolder', color: '#62bd6e' }}>Analíticos</Typography>
                                                    <Fab
                                                        aria-label='add'
                                                        color="primary"
                                                        style={{ position: 'absolute', bottom: selectedRestaurant ? 80 : 16, right: 16 }}
                                                        onMouseEnter={() => setHoverAnalytics(true)}
                                                        onMouseLeave={() => setHoverAnalytics(false)}
                                                        onClick={() => setOpenAnalytics(true)}
                                                    >
                                                        <PieChartIcon />
                                                    </Fab>
                                                </div>
                                            </Zoom>

                                            <Zoom
                                                in={selectedRestaurant !== null}
                                                timeout={600}
                                                style={{
                                                    transitionDelay: `${100}ms`,
                                                }}
                                                unmountOnExit
                                            >
                                                <Fab aria-label='add' color="primary" className={classes.fab} onClick={handleAddBranch}>
                                                    <AddIcon />
                                                </Fab>
                                            </Zoom>

                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Zoom
                        in={true}
                        timeout={600}
                        style={{
                            transitionDelay: `${100}ms`,
                        }}
                        unmountOnExit
                    >
                        <Fab
                            aria-label='add'
                            color="primary"
                            variant={isHover ? "extended" : "round"}
                            className={classes.fabAdmin}
                            onMouseEnter={() => setIsHover(true)}
                            onMouseLeave={() => setIsHover(false)}
                            onClick={handleAddAdmin}
                        >
                            <PersonAddIcon style={{ marginRight: isHover ? 8 : 0 }} />
                            <label hidden={!isHover}>Añadir administrador</label>
                        </Fab>
                    </Zoom>

                </Grid>
            }

            <Drawer anchor="right" open={openAnalytics} onClose={() => setOpenAnalytics(false)} className={classes.drawer}>

                <Grid container justify="center" className={classes.drawer}>

                    <Grid container style={{ height: 'min-content', margin: 0 }}>
                        <div className={classes.titleDiv} style={{ height: 'min-content', margin: 0 }}>
                            <Typography className={classes.cardTitle}> {selectedBranch?.Name}</Typography>
                        </div>

                        <div className={classes.titleDiv} style={{ height: 'min-content', margin: 0 }}>
                            <Typography className={classes.cardTitle}> {selectedDate}</Typography>
                        </div>
                    </Grid>

                    <div hidden={!analyticsData}>
                        <Grid container justify="center">

                            <Grid container justify="center">
                                <div className={classes.titleDiv} style={{ height: 'min-content', margin: 0 }}>
                                    <Typography style={{ margin: 'auto', fontWeight: 'bold', marginTop: 32 }}> Reservaciones</Typography>
                                </div>
                                <div id="ReservationsChart" style={{ width: "100%", display: 'flex' }}>  </div>
                                {loadChart('Reservations')}
                            </Grid>

                            <Grid container justify="center">
                                <div className={classes.titleDiv} style={{ height: 'min-content', margin: 0 }}>
                                    <Typography style={{ margin: 'auto', fontWeight: 'bold', marginTop: 32 }}> Fila Express</Typography>
                                </div>
                                <div id="QueueChart" style={{ width: "100%" }}>  </div>
                                {loadChart('Queue')}
                            </Grid>

                            <div id="tablesChart" style={{ width: "100%" }}></div>
                            <div id="sectionsChart" style={{ width: "100%" }}></div>
                        </Grid>
                    </div>

                    <div hidden={analyticsData}>
                        <Grid container justify="center" alignContent="center" style={{ height: '100%', display: "block" }}>
                            <div style={{ width: '100%', display: "flex" }}>
                                <Avatar variant="circle" style={{ width: 150, height: 150, background: '#B5B5B5', color: '#FFFFFF', margin: "auto" }} >
                                    <DescriptionIcon style={{ width: 100, height: 100 }} />
                                </Avatar>
                            </div>
                            <div style={{ width: '100%', display: "flex" }}>
                                <Typography variant="h3" style={{ margin: "auto", color: "#B5B5B5" }}>No data</Typography>
                            </div>
                        </Grid>
                    </div>

                </Grid>

            </Drawer>

            <CustomModal
                open={openModal}
                setOpen={setOpenModal}
                addMaster={addMaster}
                addRestaurant={addRestaurant}
                addBranch={addBranch}
                addAdmin={addAdmin}
                newAdmin={newAdmin}
                setNewAdmin={setNewAdmin}
                newMaster={newMaster}
                setNewMaster={setNewMaster}
                newRestaurant={newRestaurant}
                setNewRestaurant={setNewRestaurant}
                newBranch={newBranch}
                setNewBranch={setNewBranch}
                initializeVariables={initializeVariables}
            />

        </Grid>
    );
}

export default MainView;