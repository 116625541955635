import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

// Components
import Login from './components/Login/index';
import Main from './components/Main/index';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#62bd6e',
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: '#11cb5f',
    },
  },
});

function App() {

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/Login" component={Login} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
